<template>
  <v-card flat color="transparent" class="xw-full xmin-h-[100vh]">
    <v-card-title>
      <h1 class="xfont-[600] xtext-[24px] xleading-[29px]">Payment</h1>
    </v-card-title>
    <v-card-text id="stripe-cards">
      <v-progress-linear
        indeterminate
        :height="10"
        class="my-2"
        v-if="fetching"
        color="primary"
      ></v-progress-linear>

      <div
        :class="[mobile ? 'xp-[1em]' : 'xp-10']"
        class="xh-full xmin-h-[80vh] xbg-opacity-20 xgap-y-5 xw-full xrounded-lg xflex xflex-col xjustify-center xitems-center"
      >
        <h1 class="text-center xfont-bold xtext-[30px] xleading-[35px]">
          Team Basic Monthly Subcription
        </h1>
        <span
          class="xtext-[16px] xfont-semibold"
          v-if="!isSubscribed && payingMembers.length"
        >
          You are about to pay for the subscription of following
          {{ payingMembers.length }} members
        </span>
        <span
          class="xtext-[16px] xfont-semibold"
          v-if="!isSubscribed && !payingMembers.length"
        >
          Before subscribing to {{ appName }}, we need atleast 1 paying member
        </span>
        <span
          class="xtext-[16px] xfont-semibold text-center"
          v-else-if="isSubscribed"
        >
          Your team was already subscribed to Team Basic monthly subscription
          for the following
          {{ payingMembers.length }} member(s)
        </span>
        <div
          class="xflex xw-full xflex-row xjustify-center xitems-center xmax-w-[700px]"
        >
          <avatars
            :users="payingMembers"
            :class="[isSubscribed ? '' : 'mx-auto']"
            class="my-5"
            :count="mdUp ? 50 : 15"
          />
          <btn-tooltip
            tip="Add more members"
            v-if="!isSubscribed && payingMembers.length"
            color="primary"
            depressed
            class="text-none"
            @click="$router.push({ name: 'app-subscription' })"
          >
            <v-icon>mdi-plus</v-icon>
          </btn-tooltip>
        </div>

        <div
          v-if="canCheckout && !isSubscribed"
          class="xflex xflex-col xmax-w-[700px] xmx-auto xw-full"
        >
          <v-form ref="form" v-model="isValid">
            <div class="xflex xflex-col xw-full xgap-y-[1em] xmb-[1em]">
              <label>Email Address <sup>*</sup></label>
              <v-text-field
                type="email"
                flat
                outlined
                v-model="email"
                height="43px"
                hide-details="auto"
                :rules="[requiredRule(), emailRule()]"
                placeholder="Email"
                class="my-2 xtext-[#6a2c91]"
              ></v-text-field>
            </div>
            <div class="xflex xflex-col xw-full xgap-y-[1em]">
              <div
                :class="{ '!xflex-col !xjustify-start': mobile }"
                class="xflex xflex-row xjustify-between xitems-center"
              >
                <label
                  class="xmb-[10px]"
                  :class="{ 'xtext-left xw-full xorder-2': mobile }"
                >
                  Card Details <sup>*</sup>
                </label>
                <div
                  :class="{ 'xorder-1': mobile }"
                  class="xflex xflex-row xjustify-start xitems-start xgap-[10px] xorder-2"
                >
                  <v-avatar
                    size="25"
                    tile
                    :title="img.name"
                    v-for="(img, index) in cards"
                    :key="index"
                  >
                    <v-img contain :src="img.img" :alt="img.name"></v-img>
                  </v-avatar>
                </div>
              </div>
              <StripeElementCard
                ref="elementRef"
                :disabled="submitting"
                :pk="publishableKey"
                :token-data="{ email: email }"
                hide-postal-code
                :element-style="elementStyle"
                :elements-options="elementOptions"
              ></StripeElementCard>
              <small>
                Secured by
                <a href="https://stripe.com/" target="_blank">Stripe</a>
              </small>
            </div>
          </v-form>
        </div>
        <div
          class="xgap-5 xflex md:xflex-row xflex-col xjustify-center xitems-center mt-5"
        >
          <v-btn
            v-if="!isSubscribed"
            :disabled="!canCheckout || !isValid"
            color="primary"
            min-width="200"
            x-large
            :block="mobile"
            class="text-none xfont-bold"
            @click="submit"
          >
            Pay ${{ payable_amount }}
          </v-btn>

          <v-btn
            v-else
            color="primary"
            min-width="200"
            x-large
            class="text-none xfont-bold"
            @click="$router.push({ name: 'app-subscription' })"
          >
            Check Subscription
          </v-btn>

          <btn-tooltip
            tip="Add paying members"
            v-if="!isSubscribed && !payingMembers.length"
            color="primary"
            depressed
            large
            class="text-none"
            @click="$router.push({ name: 'app-subscription' })"
          >
            <v-icon>mdi-plus</v-icon> Add Paying Members
          </btn-tooltip>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { StripeElementCard } from "@vue-stripe/vue-stripe";
import { mapGetters } from "vuex";
export default {
  components: {
    StripeElementCard,
  },
  data() {
    return {
      publishableKey: null,
      loading: false,
      isSubscribed: false,
      price_per_qty: 2,
      token: null,
      isValid: false,
      email: null,
      payingMembers: [],
      submitting: false,
      fetching: false,
      cards: [
        { img: require("@/assets/cards/amex.png"), name: "American Express" },
        {
          img: require("@/assets/cards/diners-club.png"),
          name: "Discover & Diners",
        },
        {
          img: require("@/assets/cards/jcb.png"),
          name: "Japan Credit Bureau (JCB)",
        },
        { img: require("@/assets/cards/mastercard.svg"), name: "Mastercard" },
        {
          img: require("@/assets/cards/unionpay.svg"),
          name: "China UnionPay (CUP)",
        },
        { img: require("@/assets/cards/visa.svg"), name: "Visa" },
      ],
    };
  },
  computed: {
    ...mapGetters(["user"]),
    canCheckout() {
      return (
        this.publishableKey &&
        this.payingMembers.length > 0 &&
        this.price_per_qty > 0
      );
    },
    payable_amount() {
      return (this.payingMembers.length * this.price_per_qty).toFixed(2);
    },
    elementOptions() {
      return {
        appearance: {
          theme: "stripe",
        },
      };
    },
    elementStyle() {
      return {
        base: {
          iconColor: "#6a2c91",
          color: "#6a2c91",
          fontWeight: "600",
          fontFamily: "Inter, sans-serif",
          fontSize: "20px",
          fontSmoothing: "antialiased",
          ":-webkit-autofill": {
            color: "#6a2c91",
          },
          "::placeholder": {
            color: "#6a2c91",
          },
          lineHeight: "20px",
          padding: 20,
        },
        empty: {
          "::placeholder": {
            color: "#2e2e2e",
          },
          padding: 20,
          fontWeight: "400",
        },
        invalid: {
          iconColor: "#d01313",
          color: "#d01313",
        },
      };
    },
  },
  created() {
    if (!this.$auth.isAuthenticated()) {
      this.$router.push({
        name: "login",
        query: { redirect_uri: this.$route.fullPath },
      });
      return;
    }
    this.fetchInfo();
    this.resetValidation();
    this.email = this.user ? this.user.email : null;
  },
  methods: {
    fetchInfo() {
      this.fetching = true;
      this.$axios
        .get("api/subscription/checkout")
        .then(({ data }) => {
          this.isSubscribed = data.is_subscribed;
          this.publishableKey = data.publishable_key;
          this.payingMembers = data.paying_members;
          this.price_per_qty = data.price_per_qty;
        })
        .finally(() => (this.fetching = false));
    },
    async submit() {
      const stripe = this.$refs.elementRef.stripe;
      const card = this.$refs.elementRef.element;
      const billingDetails = {
        name: this.user.real_name || this.user.email,
        email: this.user.email,
      };

      // Create Payment Method
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: card,
        billing_details: billingDetails,
      });

      if (error) {
        this.appToast(error.message, "error");
        return;
      }

      if (paymentMethod) {
        this.submitting = true;
        this.appLoader(true, "Processing subscription. Please wait...");
        var email = this.email;
        try {
          // First subscription attempt
          const { data } = await this.$axios.post(`api/subscription/subscribe`, { paymentMethod, email });

          // Handle 3D Secure if required
          if (data.subscription_status === "incomplete" && data.client_secret) {
            const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(data.client_secret, {
              setup_future_usage: 'off_session',
            });

            if (confirmError) {
              this.appToast(`3D Secure authentication failed: ${confirmError.message}`, "error");
            } else if (paymentIntent && paymentIntent.status === "succeeded") {
              // 3D Secure authentication succeeded, finalize the subscription
              await this.finalizeSubscription(paymentIntent, paymentMethod);
            }
          } else {
            // Subscription succeeded without 3D Secure
            this.appToast("Successfully subscribed.", "success");
            this.$auth.fetchUser();
            this.$router.push({ name: "app-thank-you" });
          }
        } catch (error) {
          if (error.response && error.response.status === 402) {
            // If 402, 3D Secure might be required
            const { client_secret } = error.response.data;
            if (client_secret) {
              const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(client_secret);

              if (confirmError) {
                this.appToast(`3D Secure authentication failed: ${confirmError.message}`, "error");
              } else if (paymentIntent && paymentIntent.status === "succeeded") {
                // Finalize the subscription after 3D Secure
                await this.finalizeSubscription(paymentIntent, paymentMethod);
              }
            }
          } else {
            this.appToast(`Error during subscription: ${error.response?.data?.message || error.message}`, "error");
          }
        } finally {
          this.appLoader(false);
          this.submitting = false;
        }
      }
    },
    async finalizeSubscription(paymentIntent, paymentMethod) {
      try {
        var email = this.email;
        const { status } = await this.$axios.post(`api/subscription/finalize`, {
          paymentIntent, paymentMethod, email
        });

        if (status === 200) {
          this.appToast("Subscription successfully completed!", "success");
          this.$auth.fetchUser();
          this.$router.push({ name: "app-thank-you" });
        } else {
          this.appToast("Failed to finalize subscription. Please try again.", "error");
        }
      } catch (finalError) {
        this.appToast(`Error finalizing subscription: ${finalError.message}`, "error");
      }
    }
  },
};
</script>

<style lang="scss">
#stripe-cards {
  width: 100%;
  .v-messages__wrapper {
    .v-messages__message {
      color: #4a3e51 !important;
      font-size: 15px;
    }
  }
}
</style>
